<template>
  <v-list class="pt-0" dense>
    <v-subheader class="primary subtitle-2" dark>
      <span class="u-text-overflow">{{ item.name }}</span>
    </v-subheader>

    <v-list-item @click="deleteColor" class="mt-2" link>
      <v-list-item-icon>
        <v-icon color="error">fas fa-trash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="error--text" v-text="'Excluir'" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { contextMenu } from "@/main.js";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteColor() {
      this.$store.commit("setModal", {
        show: true,
        component: "warning",
        text: "Atenção!",
        caption: "Deseja mesmo deletar este email?",
        confirm: () => {
          this.$api
            .post(`email_new/delete`,{id:this.item.id})
            .then(() => {
              this.$store.commit("setModal", {
                show: true,
                component: "success",
                text: "Email excluído com sucesso",
                confirm: () => {
                  this.$store.commit("setModal", {
                    show: false,
                  });
                  contextMenu.action();
                },
              });
            })
            .catch(() => {
              this.$store.commit("setModal", {
                show: true,
                component: "error",
                text: "Ops! Falha ao tentar excluir este email",
                confirm: () => {
                  this.$store.commit("setModal", {
                    show: false,
                  });
                },
              });
            });
        },
        negate: () => {
          this.$store.commit("setModal", {
            show: false,
          });
        },
      });
    },
  },
};
</script>